<template>
  <div @mousemove="transformCard" @mouseout="cancelTransform" :class="{ 'card-wrapper--fullsize': fullSize }" class="card-wrapper" @click="$emit('click')">
    <div v-if="fullSize && statistic" class="card-wrapper__statistic">
      <div v-for="(item, i) in statistic" :key="i" class="card-wrapper__statistic-item">
        <span>{{ item.title }}: {{ item.value }}</span>
      </div>
    </div>

    <i v-if="isBackground" class="card-wrapper__main-bg" />
    <div
      v-if="type"
      :class="[
        'card-wrapper__media',
        type ? `card-wrapper__media--type-of-${type}` : '',
        {
          'card-wrapper__media--center': imageCenter,
          'card-wrapper__media--part': type,
          'card-wrapper__media--fullsize': fullSize,
        },
      ]"
    >
      <div v-if="level" class="card-wrapper__media-level">{{ level }}</div>
      <i ref="background" :class="[`card-wrapper__media-bg--${tier}`, { 'card-wrapper__media-bg--fullsize': fullSize }]" class="card-wrapper__media-bg" />

      <picture v-if="webImages.length">
        <source :srcset="webImages[0].src" type="image/webp" />
        <source :srcset="webImages[1].src" type="image/png" />
        <img
          :src="webImages[1].src"
          alt="nft fighter"
          :class="[
            'card-wrapper__media-img',
            {
              'card-wrapper__media-img--silhouette': silhouette,
              'card-wrapper__media-img--fullsize': fullSize,
              'card-wrapper__media-img--safari': isSafari,
            },
          ]"
        />
      </picture>

      <img
        v-else
        :src="image"
        alt="nft fighter"
        :class="[
          'card-wrapper__media-img',
          {
            'card-wrapper__media-img--silhouette': silhouette,
            'card-wrapper__media-img--fullsize': fullSize,
            'card-wrapper__media-img--safari': isSafari,
          },
        ]"
      />
      <slot name="media" />
    </div>
    <div v-if="$slots.content" :class="['card-wrapper__content', contentClass]">
      <div class="card-wrapper__content-inner">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWrapper",
  props: {
    image: { type: String, default: "" },
    webImages: { type: Array, default: () => [] },
    contentClass: { type: [Array, String], default: "" },
    level: { type: [String, Number], default: "" },
    tier: { type: [String, Number], default: "" },
    statistic: { type: [Array], default: null },
    imageCenter: { type: Boolean, default: false },
    silhouette: { type: Boolean, default: false },
    type: { type: String, default: "" },
    fullSize: { type: Boolean, default: false },
    isBackground: { type: Boolean, default: false },
  },
  data() {
    return {
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    };
  },
  methods: {
    transformCard(e) {
      if (!this.fullSize) {
        return;
      }

      e.preventDefault();

      const card = this.$refs.background;
      const px = Math.abs(Math.floor((100 / card.offsetWidth) * e.offsetX) - 100);
      const py = Math.abs(Math.floor((100 / card.offsetHeight) * e.offsetY) - 100);
      const lp = 50 + (px - 50) / 1.5;
      const tp = 50 + (py - 50) / 1.5;
      const ty = ((tp - 50) / 1.5) * -1.5;
      const tx = ((lp - 50) / 1.5) * 1.5;

      card.style = `transform: rotateX(${ty}deg) rotateY(${tx}deg)`;
    },
    cancelTransform() {
      if (!this.fullSize) {
        return;
      }

      this.$refs.background.style = "";
    },
  },
};
</script>

<style lang="scss">
@mixin mobile-character-size($height, $top) {
  @media screen and (max-width: $bp_mb) {
    max-height: $height * 0.7;
    top: $top * 1.2;
  }
}

$rotateDeg: 4deg;

.card-wrapper {
  position: relative;
  transform: skewX(-$rotateDeg);
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  &--fullsize {
    min-height: 100%;
    width: 100%;
    max-width: 400px;
    margin: 4em auto 0;
  }
}

.card-skew-back {
  transform: skewX($rotateDeg);
}

.card-wrapper__statistic {
  position: absolute;
  display: grid;
  grid-gap: 0.5em;
  margin-top: -3.25em;
  margin-left: -4em;
  z-index: 2;

  &-item {
    text-transform: uppercase;
    font-size: 14px;
    padding: 1em 1.5em 1em 1em;
    background: linear-gradient(270deg, #15257c 2.27%, rgba(21, 37, 124, 0) 98.68%);
  }
}

.card-wrapper__main-bg {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 17px;
  top: 17px;
  width: 100%;
  background-color: #e3e6ed;
}

.card-wrapper__media {
  position: relative;
  z-index: 1;
  flex-grow: 1;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  &--center {
    align-items: center;
  }

  &--part {
    height: 241px;
    z-index: -1;
    overflow: hidden;
  }

  &--fullsize {
    height: 580px;
  }

  &--center {
    overflow: inherit;
  }

  &--part &-img {
    position: absolute;
    z-index: 1;
    max-width: unset;
    max-height: 500px;
    top: -500px;
    left: -500px;
    right: -500px;
    bottom: -500px;
    margin: auto;
  }

  &--type-of-hiro &-img {
    left: -516px;
    top: -162px;
    max-height: 827px;

    &--fullsize {
      left: -542px;
      top: -93%;
      max-height: 745px;
      @include mobile-character-size(745px, -93%);
    }
  }

  &--type-of-zod &-img {
    top: -81px;
    left: -341px;
    max-height: 670px;

    &--fullsize {
      top: -82%;
      left: -439px;
      max-height: 663px;
      @include mobile-character-size(663px, -82%);
    }
  }

  &--type-of-pogo &-img {
    top: -161px;
    left: -497px;
    max-height: 625px;

    &--fullsize {
      top: -85%;
      max-height: 709px;
      @include mobile-character-size(709px, -85%);
    }
  }

  &--type-of-thorodin &-img {
    top: -84px;
    max-height: 672px;
    left: -373px;

    &--fullsize {
      top: -85%;
      max-height: 669px;
      left: -450px;
      @include mobile-character-size(669px, -85%);
    }
  }

  &--type-of-kira &-img {
    top: -158px;
    max-height: 620px;
    left: -513px;

    &--fullsize {
      top: -85%;
      max-height: 688px;
      left: -506px;
      @include mobile-character-size(688px, -85%);
    }
  }

  &--type-of-shenzu &-img {
    max-height: 795px;
    top: -247px;
    left: -405px;

    &--fullsize {
      max-height: 800px;
      top: -100%;
      left: -500px;
      @include mobile-character-size(800px, -100%);
    }
  }

  &--type-of-volkov &-img {
    max-height: 616px;
    top: -137px;
    left: -477px;

    &--fullsize {
      max-height: 683px;
      top: -83%;
      left: -513px;
      @include mobile-character-size(683px, -83%);
    }
  }

  &--type-of-tigra &-img {
    max-height: 669px;
    top: -180px;
    left: -460px;

    &--fullsize {
      max-height: 685px;
      top: -83%;
      left: -524px;
      @include mobile-character-size(685px, -83%);
    }
  }

  &--type-of-nash &-img {
    max-height: 592px;
    top: -170px;

    &--fullsize {
      max-height: 661px;
      top: -84%;
      @include mobile-character-size(661px, -84%);
    }
  }

  &--type-of-raiden &-img {
    max-height: 345px;
    top: -432px;
  }

  &--type-of-scorpion &-img {
    max-height: 364px;
    left: -515px;
    top: -409px;
  }

  &--type-of-mejai &-img {
    max-height: 611px;
    left: -498px;
    top: -200px;

    &--fullsize {
      max-height: 671px;
      left: -498px;
      top: -84%;
      @include mobile-character-size(671px, -84%);
    }
  }

  &--type-of-subzero &-img {
    max-height: 379px;
    left: -478px;
    top: -402px;
  }

  &--type-of-jade &-img {
    max-height: 379px;
    left: -478px;
    top: -402px;
  }
  &--type-of-fang &-img {
    top: -139px;
    left: -442px;
    max-height: 650px;

    &--fullsize {
      top: -85%;
      left: -482px;
      max-height: 714px;
      @include mobile-character-size(714px, -85%);
    }
  }
  &--type-of-shen-li &-img {
    top: -140px;
    left: -557px;
    max-height: 1284px;

    &--fullsize {
      max-height: 590px;
      top: -582px;
      left: -524px;
      @include mobile-character-size(590px, -582px);
    }
  }

  &--type-of-gaby &-img {
    top: -119px;
    left: -430px;
    max-height: 649px;

    &--fullsize {
      max-height: 673px;
      top: -81%;
      left: -484px;
      @include mobile-character-size(695px, -88%);
    }
  }

  &--type-of-adzilla &-img,
  &--type-of-karateka &-img {
    top: -304px;
    left: -523px;
    max-height: 456px;

    &--fullsize {
      transform: scale(-1, 1);
      max-height: 605px;
      top: -78%;
      left: -462px;
      @include mobile-character-size(605px, -78%);
    }
  }

  &--type-of-jeezar &-img {
    top: -231px;
    left: -447px;
    max-height: 539px;

    &--fullsize {
      max-height: 649px;
      top: -79%;
      left: -555px;
      @include mobile-character-size(649px, -79%);
    }
  }

  &--type-of-luba &-img,
  &--type-of-Luba &-img {
    top: -206px;
    left: -444px;
    max-height: 564px;

    &--fullsize {
      max-height: 690px;
      top: -80%;
      left: -514px;
      @include mobile-character-size(690px, -84%);
    }
  }

  &--type-of-hogan &-img,
  &--type-of-Hogan &-img {
    top: -206px;
    left: -444px;
    max-height: 564px;

    &--fullsize {
      max-height: 720px;
      top: -84%;
      left: -536px;
      @include mobile-character-size(720px, -85%);
    }
  }

  &--type-of-zorah &-img,
  &--type-of-Zorah &-img {
    top: -210px;
    left: -513px;
    max-height: 703px;

    &--fullsize {
      max-height: 751px;
      top: -89%;
      left: -519px;
      @include mobile-character-size(751px, -93%);
    }
  }
  &--type-of-elantra &-img,
  &--type-of-elentra &-img,
  &--type-of-hydra &-img {
    top: -121px;
    left: -507px;
    max-height: 688px;

    &--fullsize {
      max-height: 682px;
      top: -84%;
      left: -527px;
      @include mobile-character-size(682px, -84%);
    }
  }

  &--type-of-butch &-img,
  &--type-of-Butch &-img {
    top: -206px;
    left: -548px;
    max-height: 530px;

    &--fullsize {
      max-height: 720px;
      top: -84%;
      left: -536px;
      @include mobile-character-size(720px, -85%);
    }
  }

  &--type-of-kenji &-img,
  &--type-of-Kenji &-img {
    top: -206px;
    left: -444px;
    max-height: 564px;

    &--fullsize {
      max-height: 720px;
      top: -84%;
      left: -536px;
      @include mobile-character-size(720px, -85%);
    }
  }

  @media screen and (min-width: 1200px) {
    &--type-of-samuray &-img {
      left: -485px;
    }
  }

  @media screen and (max-width: 459px) {
    &--type-of-hiro &-img {
      max-height: 725px;
    }

    &--type-of-zod &-img {
      max-height: 570px;
    }

    &--type-of-pogo &-img {
      max-height: 525px;
    }

    &--type-of-thorodin &-img {
      max-height: 570px;
    }

    &--type-of-kira &-img {
      max-height: 520px;
    }

    &--type-of-shenzu &-img {
      max-height: 605px;
    }

    &--type-of-volkov &-img {
      max-height: 485px;
    }

    &--type-of-tigra &-img {
      max-height: 500px;
    }

    &--type-of-nash &-img {
      max-height: 495px;
    }

    &--type-of-raiden &-img {
      max-height: 345px;
    }

    &--type-of-scorpion &-img {
      max-height: 364px;
    }

    &--type-of-mejai &-img {
      max-height: 480px;
    }

    &--type-of-subzero &-img {
      max-height: 379px;
    }

    &--type-of-jade &-img {
      max-height: 379px;
    }

    &--type-of-fang &-img {
      max-height: 550px;
      left: -470px;
    }

    &--type-of-shen-li &-img {
      max-height: 1284px;
    }

    &--type-of-gaby &-img {
      max-height: 510px;
    }

    &--type-of-adzilla &-img,
    &--type-of-karateka &-img {
      max-height: 355px;
    }

    &--type-of-jeezar &-img {
      max-height: 450px;
    }

    &--type-of-luba &-img,
    &--type-of-Luba &-img {
      max-height: 500px;
    }

    &--type-of-hogan &-img,
    &--type-of-Hogan &-img {
      max-height: 460px;
      left: -505px;
    }

    &--type-of-zorah &-img,
    &--type-of-Zorah &-img {
      max-height: 590px;
    }

    &--type-of-elantra &-img,
    &--type-of-elentra &-img,
    &--type-of-hydra &-img {
      max-height: 565px;
    }

    &--type-of-butch &-img,
    &--type-of-Butch &-img {
      max-height: 430px;
    }

    &--type-of-kenji &-img,
    &--type-of-Kenji &-img {
      max-height: 465px;
    }
  }
}
.card-wrapper__media-level {
  position: absolute;
  z-index: 1111;
  right: 0;
  top: 0;

  padding: 10px 8px;

  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  color: black;

  background-color: #06be90;
}
.card-wrapper__media-bg {
  position: absolute;
  top: 0;
  left: -5px;
  right: -5px;
  bottom: 0;
  z-index: -1;
  background-position: center;
  background-size: cover;
  background-image: url("../../assets/img/nft-fighters/nft-background-1.jpg");
  transition: 0.25s;

  &--1 {
    background-image: url("../../assets/img/nft-fighters/nft-background-1.jpg");
  }
  &--2 {
    background-image: url("../../assets/img/nft-fighters/nft-background-2.jpg");
  }
  &--3 {
    background-image: url("../../assets/img/nft-fighters/nft-background-3.jpg");
  }
  &--4 {
    background-image: url("../../assets/img/nft-fighters/nft-background-4.jpg");
  }
  &--5 {
    background-image: url("../../assets/img/nft-fighters/nft-background-5.jpg");
  }

  &--fullsize {
    max-height: calc(4em + 585px);
  }
}
.card-wrapper__media-img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  transform: skewX($rotateDeg);
  filter: drop-shadow(-15px 0px 0px rgba(0, 0, 0, 0.125));

  &--silhouette {
    filter: brightness(0) drop-shadow(-15px 0px 0px rgba(0, 0, 0, 0.125));
  }
  &--safari {
    z-index: -10;
    transform: skewX(4deg) translateZ(99999px);
  }
}
.card-wrapper__content {
  position: relative;
  padding: 0;
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(190deg, #1f213a 7.81%, #000000 202.46%);

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 10px;
    background: linear-gradient(to right, #ffffff21 -15%, transparent 50%);
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(to bottom, #ffffff21 -15%, transparent 50%);
  }
}

@media screen and (max-width: 1115px) {
  .card-wrapper {
    &__content {
      background: transparent;

      &:after,
      &:before {
        background: transparent;
      }

      &.fighters-list__content {
        background: linear-gradient(190deg, #1f213a 7.81%, #000000 202.46%);
      }
    }

    &__media--part {
      height: 215px;
    }

    &--fullsize {
      .card-wrapper__media--part {
        height: 580px;
      }
    }
  }
}

@media screen and (max-width: $bp_mb) {
  .card-wrapper {
    &--fullsize {
      margin: 0 auto 0;
      padding: 0 2em;

      .card-wrapper__media--part {
        height: 480px;
      }
    }
  }

  .card-wrapper__statistic {
    margin-left: 0em;
    bottom: 0;
    grid-gap: 0.25em;

    &-item {
      padding: 0.5em 1.5em 0.5em 0.5em;
    }
  }
}

@media screen and (max-width: 459px) {
  .card-wrapper {
    &__media--part {
      height: 125px;
    }
  }
}
</style>
