var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card-wrapper",
      class: { "card-wrapper--fullsize": _vm.fullSize },
      on: {
        mousemove: _vm.transformCard,
        mouseout: _vm.cancelTransform,
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.fullSize && _vm.statistic
        ? _c(
            "div",
            { staticClass: "card-wrapper__statistic" },
            _vm._l(_vm.statistic, function (item, i) {
              return _c(
                "div",
                { key: i, staticClass: "card-wrapper__statistic-item" },
                [
                  _c("span", [
                    _vm._v(_vm._s(item.title) + ": " + _vm._s(item.value)),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.isBackground
        ? _c("i", { staticClass: "card-wrapper__main-bg" })
        : _vm._e(),
      _vm.type
        ? _c(
            "div",
            {
              class: [
                "card-wrapper__media",
                _vm.type ? `card-wrapper__media--type-of-${_vm.type}` : "",
                {
                  "card-wrapper__media--center": _vm.imageCenter,
                  "card-wrapper__media--part": _vm.type,
                  "card-wrapper__media--fullsize": _vm.fullSize,
                },
              ],
            },
            [
              _vm.level
                ? _c("div", { staticClass: "card-wrapper__media-level" }, [
                    _vm._v(_vm._s(_vm.level)),
                  ])
                : _vm._e(),
              _c("i", {
                ref: "background",
                staticClass: "card-wrapper__media-bg",
                class: [
                  `card-wrapper__media-bg--${_vm.tier}`,
                  { "card-wrapper__media-bg--fullsize": _vm.fullSize },
                ],
              }),
              _vm.webImages.length
                ? _c("picture", [
                    _c("source", {
                      attrs: {
                        srcset: _vm.webImages[0].src,
                        type: "image/webp",
                      },
                    }),
                    _c("source", {
                      attrs: {
                        srcset: _vm.webImages[1].src,
                        type: "image/png",
                      },
                    }),
                    _c("img", {
                      class: [
                        "card-wrapper__media-img",
                        {
                          "card-wrapper__media-img--silhouette": _vm.silhouette,
                          "card-wrapper__media-img--fullsize": _vm.fullSize,
                          "card-wrapper__media-img--safari": _vm.isSafari,
                        },
                      ],
                      attrs: { src: _vm.webImages[1].src, alt: "nft fighter" },
                    }),
                  ])
                : _c("img", {
                    class: [
                      "card-wrapper__media-img",
                      {
                        "card-wrapper__media-img--silhouette": _vm.silhouette,
                        "card-wrapper__media-img--fullsize": _vm.fullSize,
                        "card-wrapper__media-img--safari": _vm.isSafari,
                      },
                    ],
                    attrs: { src: _vm.image, alt: "nft fighter" },
                  }),
              _vm._t("media"),
            ],
            2
          )
        : _vm._e(),
      _vm.$slots.content
        ? _c("div", { class: ["card-wrapper__content", _vm.contentClass] }, [
            _c(
              "div",
              { staticClass: "card-wrapper__content-inner" },
              [_vm._t("content")],
              2
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }